<template>
    <div id="termekek" class="products py-5 position-relative">
        <div class="container-xl">                    
            <div class="row">                
                <div class="col-md-12 d-flex flex-column text-center pt-md-5">
                    <h2 class="font-header text-white text-uppercase text-center mb-5 mt-md-5">
                        Részt vevő termékek
                    </h2>
                    <div class="products-container__inner">
                        <p class="text-white font-secondary">Magyarországon valamennyi <b>Auchan áruházban</b> vagy az <b>Auchan Online Áruházában <a class="text-underline" href="https://online.auchan.hu" target="_blank">(https://online.auchan.hu/)</a></b> vásárolt a Játékban részt vevő Guarana No Sleep energiaital termékek. A választék áruházanként eltérő lehet.</p>
                    </div>
                </div>
                <div class="col-12">
                    <div class="products-container d-flex justify-content-around align-items-center flex-wrap">
                        <div class="d-flex flex-column justify-content-center p-2">
                            <img src="imgs/termek-1.png" class="brand-img img-fluid" alt="Classic"/>
                            <p class="text-white text-uppercase text-center products-item-name py-3">0.25L Guarana No Sleep<br>Classic</p>
                            <a class="btn btn-secondary" @click="setGtm('Megveszem')" href="https://online.auchan.hu/shop/guarana-no-sleep-classic-tuttifrutti-izu-szensavas-alkoholmentes-ital-250-ml.p-569972" target="_blank">
                                Megveszem
                            </a>
                        </div>
                        <div class="d-flex flex-column justify-content-center p-2">
                            <img src="imgs/termek-2.png" class="brand-img img-fluid" alt="Classic Zero"/>
                            <p class="text-white text-uppercase text-center products-item-name py-3">0.25L Guarana No Sleep<br>Classic Zero</p>
                            <a class="btn btn-secondary" @click="setGtm('Megveszem')" href="https://online.auchan.hu/shop/guarana-no-sleep-classic-zero-tuttifrutti-izu-cukormentes-szensavas-alkoholmentes-ital-250-ml.p-569971" target="_blank">
                                Megveszem
                            </a>
                        </div>
                        <div class="d-flex flex-column justify-content-center p-2">
                            <img src="imgs/termek-3.png" class="brand-img img-fluid" alt="Cactus"/>
                            <p class="text-white text-uppercase text-center products-item-name py-3">0.25L Guarana No Sleep<br>Cactus</p>
                            <a class="btn btn-secondary" @click="setGtm('Megveszem')" href="https://online.auchan.hu/shop/guarana-no-sleep-cactus-kaktusz-izesitesu-szensavas-alkoholmentes-ital-250-ml.p-575230" target="_blank">
                                Megveszem
                            </a>
                        </div>
                        <div class="d-flex flex-column justify-content-center p-2">
                            <img src="imgs/termek-4.png" class="brand-img img-fluid" alt="Aphrodisiac"/>
                            <p class="text-white text-uppercase text-center products-item-name py-3">0.25L Guarana No Sleep<br>Aphrodisiac</p>
                            <a class="btn btn-secondary" @click="setGtm('Megveszem')" href="https://online.auchan.hu/shop/guarana-no-sleep-aphrodisiac-passiogyumolcs-izu-szensavas-alkoholmentes-ital-250-ml.p-575229" target="_blank">
                                Megveszem
                            </a> 
                        </div>
                    </div>
                </div>      
            </div>         
        </div>
    </div>
</template>

<script>
export default {
    methods: {   
        setGtm(btnName){
            this.GTtrackGA4({
                'event' : 'event',
                'category': 'jatekban_reszt_vevo_termekek',
                'action': 'gomb_kattintas',
                'label': btnName,                
                'clicked_text':btnName,
                'action_type': 'gomb_kattintas'
            })
        }
    }
}
</script>