<template>
    <div class="row" id="formResultBlock">
        <div class="col-12 col-xl-8 offset-xl-2">
            <div class="bg-white form-inner-container">
                <div class="">
                    <!-- SIKERES ÜZENET -->
                    <div v-if="type == 'success'" class="text-center">
                        <img src="imgs/ic-success-form.svg" class="img-fluid mb-5"/>                        
                        <h4 class="text-primary mb-5 text-uppercase mt-4">Köszönjük, hogy beküldted a bizonylat adatait!</h4>
                        <p class="mb-5 text-dark font-secondary">Ne feledd, a nyereményjáték <b>2022.december 07-ig</b> tart! Növeld esélyedet és játssz újra! Az eredeti bizonylat sértetlen, ép állapotban történő megőrzésének határideje <b>2023. január 07.</b></p>
                    </div>
                    <!-- LIMITEK ELÉRÉSE -->
                    <div v-else-if="type == 'limit_reached'" class="text-center">
                        <h3 class="text-red mb-5 text-uppercase">Sikertelen pályázatbeküldés</h3>
                        <!-- <img class="mb-5" src="/imgs/ic-not-success-form.svg" /> -->
                        <!-- TODO: szövegek kellenek -->
                        <p v-if="errorCode == 'maximum_daily_uploads'">Egy Játékos egy adott napon csak {{$api_conf.maxOneDayPcs}} Pályázatot küldhet be.</p>
                        <p v-if="errorCode == 'limit_reached_for_that_day'">Egy adott vásárlási napra vonatkozóan egy játékos csak {{$api_conf.maxOnePurchaseDayPcs}} Pályázatot küldhet be.</p>
                        <p v-if="errorCode == 'maximum_uploads'">Egy játékos összesen maximum {{$api_conf.maxFullPcs}} Pályázatot küldhet be.</p>
                        <p v-if="errorCode == 'nav_code'">Egy vásárlást igazoló blokk adatai kizárólag egy érvényes Pályázatban küldhetők be.</p>
                    </div>
                    <!-- SIKERTELEN ÜZENET -->
                    <div v-else class="text-center">
                        <h3 class="text-red mb-5 text-uppercase">Sikertelen pályázatbeküldés</h3>
                        <!-- <img class="mb-5" src="/imgs/ic-not-success-form.svg" />                     -->
                        <p class="mb-5 font-secondary">Lehet, hogy elütöttél valamit vagy ezzel a blokkal már játszottál. Ellenőrizd a blokkot, és írd be újra az adatokat!</p>
                    </div>
                    <!-- GOMBOK -->
                    <div class="text-center d-md-flex justify-content-center mt-4">
                        <button class="mt-0 mx-md-2  btn btn-secondary mb-4" @click="$emit('back-to-form-and-my-sendings')">
                            Beküldéseim
                        </button>
                        <button class="mt-0 mx-md-2 btn btn-third mb-4" @click="$emit('back-to-form')">
                            Új beküldés
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>    
</template>

<script>
/**
 * limit errors:
 * - maximum_daily_uploads : 5
 * - maximum_uploads: 30
 * - limit_reached_for_that_day : 2
 */

export default {
    props:{
        type:{
            required:true,
            type: String
        },
        errorCode:{
            required:false,
            type:String
        }
    },
  
}
</script>