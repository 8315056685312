<template>
    <div id="kapcsolat" class="contact pt-4 pb-5 py-md-5">
        <div class="container-xl">                                
            <div class="row pt-5">    
                <div class="col-12 col-md-10 offset-md-1">
                    <div class="form-inner-container">                                        
                        <h2 class="font-header text-uppercase text-black text-center mt-md-3 mb-5">kapcsolat</h2>
                        <div class="row" v-if="!formSended">                    
                            <div class="col-12">
                                <p class="text-center mb-0 text-dark mb-md-5 font-secondary">
                                Kérdésed van? Írj nekünk! A Játék ideje alatt, illetve 2023. január 07-ig információs e-mail címet <a href="mailto:auchanguarana@a4c.hu" class="text-underline" target="_blank">(auchanguarana@a4c.hu)</a> üzemeltetünk, melyen a megküldött megkeresések munkanapokon kerülnek megválaszolásra. Kérdéseidet az <b>auchanguarana@a4c.hu</b> címen vagy az alábbi űrlapon keresztül várjuk!</p>
                            </div>
                        </div>            
                        <!-- ITT KEZDŐDIK A FORM --> 
                        <ValidationObserver 
                            class="col-12 col-xl-10 offset-xl-1"
                            ref="observerCont" 
                            v-slot="{ handleSubmit }"
                            v-if="!formSended"
                        >
                            <form 
                                class=""
                                @submit.prevent="handleSubmit(onSubmit)"
                            >
                            
                                <div class="row">
                                    <div class="col-12 col-md-6">
                                        <CustomInput
                                            label="Vezetéknév"
                                            name="last_name"
                                            placeholder="pl.: Molnár"
                                            tooltip="A játékos személyi igazolványába jegyzett vezetéknév."
                                            v-model="form.last_name"
                                            rules="required|min:2|customRegex"
                                        />
                                    </div>
                                    
                                    <div class="col-12 col-md-6">
                                        <CustomInput
                                            label="Keresztnév"
                                            name="first_name"
                                            placeholder="pl.: Kata"
                                            tooltip="A játékos személyi igazolványába jegyzett keresztnév."
                                            v-model="form.first_name"
                                            rules="required|min:2|customRegex"
                                        />
                                    </div>

                                    <div class="col-12 col-md-6">
                                        <CustomInput
                                            label="E-mail cím"
                                            name="email"
                                            placeholder="pl.: molnarkata@gmail.com"
                                            tooltip="A játékos e-mail címe."
                                            v-model="form.email"
                                            rules="required|email"
                                        />
                                    </div>
                                    
                                    <!-- TELEFONSZÁM -->
                                    <div class="col-12 col-md-6 phone-wrap">
                                        <div class="form-label m-0">Telefonszám</div>
                                        <div class="d-flex w-100 align-items-start">
                                            <span class="me-2 d-block phone-prefix">+36</span>
                                            <CustomInput
                                                class="w-100"
                                                label=""
                                                name="phone"
                                                placeholder="Pl.: 20 123 1234"
                                                tooltip="Egy telefonszám, amelyen a játékos elérhető."
                                                v-model="form.phone"
                                                v-mask="['##-###-####', '#-###-####']"
                                            />
                                        </div>
                                    </div>

                                    <div class="col-12">
                                        <CustomText
                                            label="Üzenet"
                                            name="message"
                                            v-model="form.message"
                                            rules="required"
                                            :placeholder="'Írd ide az üzenetet...'"
                                        />
                                    </div>

                                    <div class="col-12">
                                        <CheckBox
                                            class="my-2"
                                            name="condition"
                                            v-model="form.condition"
                                            :checked="form.condition"
                                            rules="required|acceptConditions"
                                        >
                                        <p class="font-secondary mb-0">
                                            <span>
                                                Hozzájárulok, hogy megadott adataimat a Kapcsolatfelvétel során az adatkezelő kezelje. Bővebb információ az
                                            </span>
                                            <a 
                                                href="/pdf/adatkezelesi_tajekoztato.pdf"
                                                target="_blank"
                                                class="text-primary text-decoration-underline"
                                            >
                                                Adatkezelési tájékoztatóban.
                                            </a>
                                            <span class="fw-bold text-red"> *</span>
                                            </p>
                                        </CheckBox>
                                    </div>
                                    
                                    <div class="row">
                                        <div class="col-12 d-flex">
                                            <p class="text-primary text-center mt-5 mb-4">
                                                <span class="fw-bold text-red">*</span>
                                                <span>&nbsp;</span>
                                                <span class="text-dark font-secondary">A csillaggal jelölt mezők kitöltése szükséges.</span>
                                            </p>
                                        </div>
                                    </div>                                    
                                </div>        
                                <div class="row" v-if="loginShow">
                                        <div class="col-12 d-flex justify-content-center my-2 my-sm-4">
                                            <input 
                                                class="btn btn-secondary btn-submit" 
                                                type="submit" 
                                                value="Üzenet küldése"                                            
                                            >
                                        </div>
                                    </div>    
                            </form>
                        </ValidationObserver>

                        <!-- Visszajelző screen -->
                        <div v-if="formSended" class="text-center">
                            <img v-if="formSubmitStatus != 'not-success'" src="imgs/ic-success-form.svg" class="img-fluid"/> 
                            <p class="p-4 font-secondary text-dark text-center" :class="[{'contact-success': formSubmitStatus == 'success'},{'contact-warning': formSubmitStatus != 'success' }]" v-html="formSubmitResult"></p>
                            <div class="text-center mt-4">
                                <button 
                                    class="btn btn-secondary"    
                                    @click="goBack()"                            
                                >
                                    Új üzenet küldése
                                </button>
                            </div>
                        </div>                           
                    </div>
                </div>    
            </div>      
        </div>                
    </div>        
</template>

<script>
import CustomInput from '@/components/base/CustomInput.vue'
import CustomText from '@/components/base/CustomText.vue'
import CheckBox from '@/components/base/CheckBox.vue'
import { ValidationObserver } from 'vee-validate'
import {mask} from 'vue-the-mask'

export default {
    directives: {mask},
    components: {
        ValidationObserver,
        CustomInput,
        CustomText,
        CheckBox
    },
    data () {
        return {
            form: {
                first_name: '',
                last_name: '',
                email: '',
                phone: '',
                message: '',
                condition: null
            }, 
            successFormSending: false,
            formSubmitted: false,
            formSubmitResult: "",
            formSubmitStatus: "",
            formSended : false
        }
    },
    methods: {
        defaultFormState(){
            this.form = {
                first_name: '',
                last_name: '',
                email: '',
                phone: '',
                message: '',
                condition: null
            }                                              
        },
        goBack(){
            this.formSubmitted = false     
            this.formSended = false
            this.formSubmitResult= ""
            this.formSubmitStatus = ""      
        },
        onSubmit(){
            var _this = this;            

            if (!_this.formSubmitted) {
                _this.formSubmitted = true

                var sendData = JSON.parse(JSON.stringify(this.form));            
                
                //TESZT: hibaüzi előhozása - a this.post sort kommenteld ki, és ezt hívd be helyette                
                this.post('message', sendData)
                .then((response) => {                   
                    //SUCCESS message send
                    if (response.data.status === true) {                        
                        // console.log('sikeres üzenet küldés')
                        _this.formSended = true
                        _this.formSubmitStatus = 'success'
                        _this.formSubmitResult= "Köszönjük az üzeneted! Az űrlap sikeresen elküldve.<br/> Hamarosan válaszolunk!"
                        _this.defaultFormState()   
                        _this.setGtm('sikeres')        
                    } 
                    _this.formSubmitted = false // to avoid the double form submit                    

                }).catch(function (err) {
                    //NOT SUCCESS msg send
                    console.log(err);                    
                    _this.goToError(err)     
                    _this.setGtm('sikertelen')                                                    
                    _this.formSubmitted = false // to avoid the double form submit                    
                });
                let element = document.querySelector('#kapcsolat')
                this.scrollToElement(element, -80)
            } else {
                console.log('double form submit')
            }

        },
        goToError(err){            
            
          this.setErrorMsgs(err, 'observerCont').then(()=>{                
              let firstError = document.querySelector('.contact .input-error')
              this.scrollToElement(firstError, 80)       
          }).catch(()=>{
              //the error not bound to a field
            this.formSended = true  
            this.formSubmitStatus = 'not-success'
            this.formSubmitResult= "<p class='input-error'>Hiba az üzenetküldés során, kérjük, próbálja meg újra.</p>"
          })                                   
        },
        setGtm(result){
            this.GTtrackGA4({
                'event' : 'event',
                'category': 'kapcsolat',
                'action': 'kuldes_gomb_kattintas',
                'label': result,
                'button': 'kuldes',                
                'clicked_text':'Üzenetet küldök',
                'action_type': 'gomb_kattintas',
                'success': result
            })
        }
    }
}
</script>