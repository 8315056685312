<template>
    <div id="jatekszabaly" class="rules position-relative">
        <div class="container-xl">

            <div class="row px-3 px-md-0">
                <h2 class="text-uppercase display-2 text-yellow text-center mb-5 mt-0 ">
                    A játék lépései
                </h2>
                <div class="col-12 col-md-10 offset-md-1 rules-container">
                    <div class="row">
                        <div class="col-12">
                            <h3 class="text-black text-center pb-md-3 mb-0 text-uppercase">Vásárolj!</h3>
                        </div>
                        <div class="col-12 col-md-4 text-center">
                            <img src="imgs/rules-01.svg" class="img-fluid mb-md-0" alt="Vásárolj"/>
                        </div>
                        <div class="col-12 col-md-8 d-flex flex-column justify-content-center">
                            <div class="d-flex justify-content-center rules__text flex-md-row flex-column align-items-center align-items-md-start">
                                <img class="me-3" src="imgs/num-1.svg">
                                <p class="text-start">Vásárolj egyszerre <span class="fw-800">legalább 3 darabot</span> a promócióban részt vevő <span class="text-primary text-decoration-underline"><a v-scroll-to="{ el: '#termekek', offset: -80 }" class='fw-800 text-yellow rules__link'>Guarana No Sleep energiaitalokból </a></span> az <span class="fw-800">Auchan áruházakban</span>, vagy az <span class="fw-800">Auchan Online Áruházban </span>(a <a href="https://online.auchan.hu" target="_blank"><span class="rules__link text-yellow text-decoration-underline fw-800">https://online.auchan.hu</span></a> oldalon keresztül), hogy részt vehess a játékban.</p>
                            </div>
                            <div class="d-flex rules__text flex-md-row flex-column align-items-center align-items-md-start">
                                <img class="me-3" src="imgs/num-2.svg">
                                <p class="text-start w-100">A nyereményjáték időtartama:<br/><span class="fw-800">2022. november 10 - december 07.</span></p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-10 offset-md-1 rules-container my-5">
                    <div class="row">
                        <div class="col-12">
                            <h3 class="text-black text-center pb-md-3 mb-0 text-uppercase">Regisztrálj!</h3>
                        </div>
                        <div class="col-12 col-md-4 text-center">
                            <img src="imgs/rules-02.svg" class="img-fluid mb-md-0" alt="Regisztrálj"/>
                        </div>
                        <div class="col-12 col-md-8 d-flex flex-column justify-content-center">
                            <div class="d-flex justify-content-center rules__text flex-md-row flex-column align-items-center align-items-md-start">
                                <img class="me-3" src="imgs/num-3.svg">
                                <p class="text-start w-100">Regisztrálj, majd töltsd fel a vásárlást igazoló bizonylat adatait és játssz a nyereményekért!</p>
                            </div>
                            <div class="d-flex rules__text flex-md-row flex-column align-items-center align-items-md-start">
                                <img class="me-3" src="imgs/num-4.svg">
                                <p class="text-start">A vásárlást igazoló eredeti bizonylat sértetlen, ép állapotban történő megőrzése <span class="fw-800">2023. január 07-ig</span> szükséges.</p>
                            </div>
                            <div class="d-flex rules__text flex-md-row flex-column align-items-center align-items-md-start">
                                <img class="me-3" src="imgs/num-5.svg">
                                <p class="text-start">Ne feledd, minél többször vásárolsz a promócióban részt vevő Guarana No Sleep energiaitalokból egyidejűleg legalább 3 darabot, annyiszor több esélyed van a nyerésre!</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-10 offset-md-1 rules-container">
                    <div class="row">
                        <div class="col-12">
                            <h3 class="text-black text-center pb-md-3 mb-0 text-uppercase">Sorsolás!</h3>
                        </div>
                        <div class="col-12 col-md-4 text-center">
                            <img src="imgs/rules-03.svg" class="img-fluid mb-md-0" alt="Nyerj"/>
                        </div>
                        <div class="col-12 col-md-8 d-flex flex-column justify-content-center">
                            <div class="d-flex justify-content-center rules__text flex-md-row flex-column align-items-center align-items-md-start">
                                <img class="me-3" src="imgs/num-6.svg">
                                <p class="text-start">A játék ideje alatt előre meghatározott <span class="fw-800">nyerőidőpontokban</span> megnyerhető:<br/><span class="fw-800">hetente 1 db Guarana No Sleep mini hűtő</span>
                                <br/><span class="fw-800">naponta 1 db Guarana No Sleep sapka</span>
                                </p>
                            </div>
                            <div class="d-flex rules__text flex-md-row flex-column align-items-center align-items-md-start">
                                <img class="me-3" src="imgs/num-7.svg">
                                <p class="text-start">A játék végét követően a <span class="fw-800">fődíj - egy bagoly állatkerti örökbefogadásának sorsolására 2022.12.12 14:00 órakor kerül sor.</span></p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 text-center">
                    <h4 class="text-yellow good-luck py-4">Jó játékot, sok szerencsét kívánunk!</h4>
                </div>
                <div class="col-12 flex-wrap flex-md-nowrap justify-content-center d-flex pb-5">                                                      
                    <button
                        class="btn btn-primary mb-4 mb-md-0 me-0 me-md-4"
                        v-scroll-to="{el:!$store.state.user ? '#regisztracio' : '#palyazat',  offset: -80}"
                        @click="setGtm('Játszom')"
                    >
                        Játszom
                    </button>
                    <a class="btn btn-secondary " :href="`/pdf/Jatekszabalyzat.pdf?` + TimeStamp" target="_blank" @click="setGtm('Játékszabályzat')">
                        Játékszabályzat
                    </a> 
                </div>  
            </div> <!-- end of ROW -->
        </div>        
    </div>
</template>

<script>
export default {
    data () {
        return {
         
        }
    },
    methods:{
        setGtm(btnName){
            this.GTtrackGA4({
                'event' : 'event',
                'category': 'jatekleiras',
                'action': 'gomb_kattintas',
                'label': btnName,                
                'clicked_text':btnName,
                'action_type': 'gomb_kattintas'
            })
        }
    }
}
</script>