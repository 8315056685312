<template>
     <div class="file-upload-block form-row mb-0">
        <div class="col-12">                       
                <div class="img-uploads receipt-row">     
                    <div class="col-12">
                        <div class="align-center file__upload p-3 d-flex flex-wrap">
                        
                            <label class="img-upload col-12 col-md-6" for="receipt">
                                <input
                                    type="file"
                                    id="receipt"
                                    ref="receipt"
                                    name="receipt"
                                    @change="handleFilesUpload()"
                                />
                                <div class="img-upload__text d-flex align-items-center">
                                    <img src="imgs/ic-blokk.svg" class="img-fluid me-2"/>
                                    <div>
                                        <p class="m-0 img-upload__text--title">Blokk feltöltése<span class="text-red fw-bold">*</span></p>
                                        <p class="font-secondary text-dark m-0 fs-14">Max 5 MB - png vagy jpeg formátum</p>
                                    </div>
                                </div>
                            </label>
                            <!-- feltöltött fájlok -->
                            <div class="uploaded-files text-end col-12 col-md-6">                              
                                <label class="form-label mt-3 mt-lg-0" v-if="uploadedFiles.length > 0">Feltöltött fájlok:</label>                              

                                <div v-if="form.receipt != null" class="d-flex justify-content-end align-items-center">
                                    <span class="upload-text">{{ form.receipt.name }}</span>
                                    <div
                                        class="delete"
                                        @click="delUploadedFile(form.receipt)"
                                    ></div>
                                </div>
                                <div v-if="form.receipt2 != null" class="d-flex justify-content-end align-items-center">
                                    <span class="upload-text">{{ form.receipt2.name }}</span>
                                    <div
                                        class="delete"
                                        @click="delUploadedFile(form.receipt2)"
                                    ></div>
                                </div>
                                <div v-if="form.receipt3 != null" class="d-flex justify-content-end align-items-center">
                                    <span class="upload-text">{{ form.receipt3.name }}</span>
                                    <div
                                        class="delete"
                                        @click="delUploadedFile(form.receipt3)"
                                    ></div>
                                </div> 
                                <div v-if="form.receipt4 != null" class="d-flex justify-content-end align-items-center">
                                    <span class="upload-text">{{ form.receipt4.name }}</span>
                                    <div
                                        class="delete"
                                        @click="delUploadedFile(form.receipt4)"
                                    ></div>
                                </div> 
                                <div v-if="form.receipt5 != null" class="d-flex justify-content-end align-items-center">
                                    <span class="upload-text">{{ form.receipt5.name }}</span>
                                    <div
                                        class="delete"
                                        @click="delUploadedFile(form.receipt5)"
                                    ></div>
                                </div>  
                            </div>
                            <!-- fájl feltöltés error -->
                            <div class="col-12 col-lg-6"> 
                                <div
                                    class="uploaded-error error"
                                    ref="uploadederror"
                                ></div>
                                <span v-if="imageError || imageError2" class="error input-error">A blokk képének feltöltése kötelező</span>
                                <span v-if="imageApiError" class="error input-error">Hiba a fájl feltöltése során. Kérjük, töltsön fel egy új fájlt.</span>
                            </div>
                        </div> 
                    </div>                     
                                                                     
                    
                    
                </div>
        </div>
        </div>
</template>

<script>
export default {
    props:['imageApiError', 'imageError'],
    data(){
        return{
            maxFileUploadNum:5,
            uploadedFiles: [],
            imageError2: null,
            maxFileSizeMb: 5,
            form:{
                receipt: null,
                receipt2: null,
                receipt3: null,
                receipt4: null,
                receipt5: null
            }
        }
    },
    watch:{
        form:{
            deep:true,
            handler(){
                let _this = this
                _this.$emit('changeFile', _this.form)
            }
        }
    },
    methods:{
        handleFilesUpload() {
            let _this = this;
            //remove the error msg if there was
            this.$refs.uploadederror.innerHTML = "";

            let uploadedItem = this.$refs.receipt.files[0];

            //check file size 5Mb max
            let maxFileSize = 1024 * 1024 * _this.maxFileSizeMb;
            let fileSize = uploadedItem.size;

            //check file fomat
            // let isGoodExtension = this.checkFileFormat(uploadedItem.name);

            //check file size - max X Mb
            if (fileSize > maxFileSize) {
                this.$refs.uploadederror.innerHTML = `"<p class='input-error'>Túl nagy a fájl mérete (max. ${_this.maxFileSizeMb}Mb)</p>"`
                    // "Túl nagy a fájl mérete (max. 5Mb)";
                // } else if (isGoodExtension == false) {
                //   this.$refs.uploadederror.innerHTML = "Nem megfelelő formátum.";
            } else {
                if (_this.uploadedFiles.length < parseInt(_this.maxFileUploadNum)) {
                //max 5 item could be uploaded

                let nameIndex = _this.uploadedFiles.find(
                    (item) => item.name == uploadedItem.name
                );

                if (nameIndex == undefined) {
                    _this.uploadedFiles.push(uploadedItem);
                } else {
                    this.$refs.uploadederror.innerHTML = "<p class='input-error'>Megegyező fájl név.</p>"
                }
                } else {
                _this.$refs.uploadederror.innerHTML = "<p class='input-error'>Nem tölthető fel több fájl.</p>"
                }
            }

            this.fillReceipts();

            //set the default state for the input type=file
            this.$refs.receipt.value = ""

            if (this.form.receipt != null) {
                this.imageError2 = false;
            }
        },
        fillReceipts() {
            let _this = this;

            _this.form.receipt = null;
            _this.form.receipt2 = null;
            _this.form.receipt3 = null;
            _this.form.receipt4 = null;
            _this.form.receipt5 = null;

            this.uploadedFiles.forEach(function (item, i) {
                if (i == 0) {
                _this.form.receipt = item;
                } else if (i == 1) {
                _this.form.receipt2 = item;
                } else if (i == 2) {
                   _this.form.receipt3 = item;
                } else if (i == 3) {
                   _this.form.receipt4 = item;
                } else if (i == 4) {
                   _this.form.receipt5 = item;
                }
            });
        },
        delUploadedFile(delItem) {
            let _this = this
            let delItemIndex = this.uploadedFiles.indexOf(delItem);

            if (delItemIndex != -1) {
                _this.uploadedFiles.splice(delItemIndex, 1);
            }

            this.$refs.uploadederror.innerHTML = "";
            this.fillReceipts();
        },
    }
}
</script>