<template>
    <div id="palyazat" class="send-form pt-5 pb-4 pb-md-5">
        <div class="container-xl">            
            
            <div class="row" v-if="$store.state.promoStatus == 'live'">                
                <!-- ITT KEZDŐDIK A FORM -->
                <div v-if="formSendedStatus == null" class="col-12 col-xl-10 offset-xl-1">
                    <ValidationObserver 
                        class=""
                        ref="observerSend" 
                        v-slot="{ handleSubmit }"
                    >               
                        <div class="col-12 pb-5">
                            <h2 class="font-header text-white text-center mb-4 mt-0 mt-md-5">
                                Pályázat beküldés
                            </h2>
                        </div>
                        <div class="col-12 col-md-10 offset-md-1">
                            <p class="text-center text-black fw-500 mb-5 font-secondary">
                                Mielőtt elküldöd az adatokat, kérjük ellenőrizd le, hogy minden információt pontosan, a bizonylaton szereplő módon adtál-e meg! Utólag az elküldött adatokat nem lehet módosítani, csak a helyesen megadott bizonylat adatokkal válhatsz nyertessé.
                                Csak az egyszerre legalább 3 db a promócióban részt vevő Guarana No Sleep energiaital termék vásárlását igazoló bizonylat vesz részt érvényesen játékban.<br/><br/><a class="fw-bold text-decoration-underline" v-scroll-to="{ el: '#termekek', offset: -80 }">Beküldés előtt tájékozódj a játékban résztvevő termékekről!</a>
                            </p>
                        </div>

                        <form
                            class="bg-white form-inner-container pt-3" 
                            @submit.prevent="handleSubmit(onSubmit)"
                        >       
                                <div class="send-form-container row h-100 pt-md-5">
                                    
                                    <!-- FORM FIELDS -->
                                    <div class="send-form-form col-12 col-md-6">
                                        <div class="row">
                                            
                                            <label for="">Vásárlás típusa <span class="text-primary fw-bold">*</span></label>
                                            <div class="col-12 mb-2">
                                                <CustomRadio
                                                    id="buy_type"
                                                    name="buy_type" 
                                                    firstLabel="Boltban vásárolt"
                                                    firstValue="shop"
                                                    secondLabel="Online vásárolt"
                                                    secondValue="online"
                                                    v-model="form.buy_type"
                                                    rules="required"
                                                    :firstCheck="form.buy_type"                                                    
                                                />
                                            </div>
                                            <!-- <div class="send-form-img-mobile col-12 d-md-none" v-if="form.buy_type == 'online'">
                                                <img src="/imgs/online_szamla.jpg" class="img-fluid mb-4" alt="online számla" />
                                            </div> -->
                                            <!-- SHOP FORM -->
                                            <div v-if="form.buy_type == 'shop'">
                                                <div class="col-12 mb-4 mt-4">
                                                    <DropDown
                                                        label="Hol történt a vásárlás?"
                                                        name="where_bought"
                                                        placeholder="Válassz"
                                                        :options="offlineShops"
                                                        @selectedItem="form.where_bought = $event"
                                                        rules="required"
                                                    />
                                                </div>                                               
                                                <div class="col-12 mb-2">
                                                    <CustomInput
                                                        v-mask="'########'"
                                                        label="AP kód"
                                                        name="ap_code"
                                                        tooltip="AP betűk után A és 8 db számjegy."
                                                        v-model="form.ap_code"
                                                        rules="required|min:8"
                                                        code
                                                    />
                                                </div>
                                            </div>
                                            <!-- offline vásárlás -->
                                            <div class="col-12" v-if="form.buy_type == 'shop'">
                                                <CustomInput
                                                    label="NAV kód"
                                                    v-mask="{mask: 'FFFFF', tokens: hexTokens}"
                                                    name="nav_code"
                                                    placeholder=""
                                                    tooltip="5 karakter"
                                                    v-model="form.nav_code"
                                                    rules="required|length:5"
                                                />
                                            </div>
                                            <div class="col-12 mb-2 mt-4" v-if="form.buy_type == 'online'">
                                                <CustomInput
                                                    label="Rendelés száma"
                                                    name="online_code"
                                                    v-mask="{mask: 'GGGG', tokens: hexTokens}"
                                                    tooltip="A rendelés számának utolsó 4 karaktere."
                                                    v-model="form.online_code"
                                                    rules="required|length:4"
                                                />
                                            </div>                                 
                                            <div class="col-12">
                                                <div class="form-label mb-0">Vásárlás időpontja <span class="label-red">*</span></div>
                                            </div>
                                            <div class="col-12 mb-4" :class="[form.buy_type == 'shop' ? 'col-md-6' : '']">
                                                <SelectDate
                                                    name="purchase_time"
                                                    label="" 
                                                    placeholder="ÉÉ-HH-NN"
                                                    @selectedDate="form.date_of_bought = dateTimeToSqlFormat( $event)"
                                                    rules="required"
                                                />
                                            </div>                                    
                                            <div class="col-12 col-md-6 mb-4" v-if="form.buy_type == 'shop'">
                                                <SelectTime
                                                    class="" 
                                                    name="time_of_bought"
                                                    label="" 
                                                    placeholder="ÓÓ:PP"
                                                    @selectedTime="form.time_of_bought = timeToSqlFormat($event)"
                                                    rules="required"
                                                />
                                            </div>   
                                            <div class="col-12col-md-6">
                                                <DropDown
                                                    label="Megvásárolt Guarana No Sleep energiaital termékek darabszáma"
                                                    name="product_count"
                                                    placeholder="Válassz"
                                                    :options="productPcs"
                                                    @selectedItem="form.product_count = $event"
                                                    rules="required"
                                                />
                                            </div>
                                        </div>   
                                                                            
                                    </div><!-- end of FORM FIELDS -->
                                    
                                    <!-- RECIPE IMG - desctop-->
                                    <div class="send-form-img col-12 col-md-6 d-flex align-items-start">
                                        <img v-if="form.buy_type == 'shop' " src="imgs/receipt.jpg" class="img-fluid mb-4" alt="blokk fotó" />
                                        <img v-else src="imgs/online_szamla.jpg" class="img-fluid mb-4" alt="online számla" />
                                    </div><!-- end of RECIPE IMG -->
                                </div>
                                 <!-- ACCEPT TERMS -->
                                <!-- <div class="class-12">
                                    <CheckBox
                                        class="mt-2"
                                        name="acknowledge"
                                        v-model="form.acknowledge"
                                        :checked="form.acknowledge"
                                        rules="required|acceptConditions"   
                                    >
                                        <p class="mb-0 font-secondary"> 
                                            <span>Igazolom, hogy az adott bizonylaton legalább 2500 Ft értékben szerepel a Játékban rész vevő Zewa, Libresse, Libero és Tena Lady termék.</span>
                                            <span>&nbsp;</span>
                                            <span class="text-red fw-bold">*</span>    
                                        </p>
                                    </CheckBox>
                                </div> -->

                                <!-- checkbox list -->
                                <!--<div class="row">
                                    <label for="product" class="mb-2">Melyik termékkategóriá(k)ból vásároltál az adott bizonylaton?</label>
                                    <CheckBox v-for="(category, cind) in prodCategories" :key="cind"
                                        class="mt-2 col-12 col-md-6"
                                        :name="`product_${cind}`"                                                                                                            
                                        :label="category"
                                        @input="fillProductCategory(category, $event, cind)"
                                    >                                    
                                    </CheckBox>
                                </div>
                                <div v-if="productCategoryListErrors">
                                    <p class="input-error">Kérjük, válassz termékkategóriát</p>
                                </div>-->

                                <!-- FILE UPLOAD -->
                                <FileUpload v-if="needFileUpload" :imageApiError="imageApiError" @changeFile="changeFile" :imageError="imageError" />
                                
                                <!-- NOTE -->
                                <div class="row">
                                    <div class="col-12 d-flex font-secondary">
                                        <p class="text-primary text-center my-4">
                                            <span class="text-red fw-bold">*</span>
                                            <span>&nbsp;</span>
                                            <span class="text-dark">A csillaggal jelölt mezők kitöltése szükséges.</span>
                                        </p>
                                    </div>
                                </div>

                                <!-- SUBMIT --> 
                                <div class="row">
                                    <div class="col-12 d-flex justify-content-center my-2 my-sm-4">
                                        <input 
                                            class="btn btn-secondary btn-submit" 
                                            type="submit" 
                                            value="Pályázat beküldése"
                                            @click="checkErrors()"
                                        >
                                    </div>
                                </div>
                        </form>
                        
                    </ValidationObserver> <!-- ITT VÉGZŐDIK A FORM -->
                </div>
                <div v-else>
                    <SendFormResult 
                        :type="formSendedStatus" 
                        :errorCode="formSendedErrorCode"
                        @back-to-form="goBackToForm()"
                        @back-to-form-and-my-sendings="goBackToFormMySendings()"
                    />
                </div>
                
            </div>
            <div class="row" v-else-if="$store.state.promoStatus == 'before'">
                 <div class="col-12 col-xl-10 offset-xl-1">                    
                    <div class="bg-white form-inner-container text-center py-5">
                        <h2 class="font-header display-2 text-secondary text-center mb-4 mb-sm-5 mt-0 mt-md-5">
                            Pályázat beküldés
                        </h2>
                        <p class="font-secondary">A promóció időszaka még nem kezdődött el.</p>
                    </div>
                 </div>
            </div>
        </div>
    </div>
    
</template>

<script>
/**
 * FORM.BUY_TYPE
 * The form and API post is change there's fields by form.buy_type
 * 
 * FORMSENDEDSTATUS
 * formSendedStatus switched between the form and SendFormResult
 * if formSendedStatus == null --> show the form
 * if formSendedStatus != null --> possible values: success, not_success --> show the SendFormResult
 * 
 *  
 */

import { ValidationObserver } from 'vee-validate'
import CustomRadio from '@/components/base/CustomRadio.vue'
import CustomInput from '@/components/base/CustomInput.vue'
import DropDown from '@/components/base/DropDown.vue'
//import CheckBox from '@/components/base/CheckBox.vue'
import SelectDate from '@/components/base/SelectDate.vue'
import SelectTime from '@/components/base/SelectTime.vue'
import {mask} from "vue-the-mask"
import SendFormResult from '@/components/SendFormResult.vue'
import FileUpload from '@/components/FileUpload.vue'

export default {
    directives: {mask},
    components: {
        ValidationObserver,
        CustomRadio,
        CustomInput,
        DropDown,
        //CheckBox,
        SelectDate,
        SelectTime,        
        SendFormResult,
        FileUpload
    },
    data () {
        return {
            needFileUpload:false,
            imageError:false,
            // needAcknowledge: false,
            productCategoryListErrors:false,
            form: {
                buy_type: 'shop',
                where_bought: '',
                ap_code: '',
                nav_code: '',
                online_code:'',
                date_of_bought: '',
                time_of_bought: '',   
                purchase_time:'',
                product_count: '',                
                // acknowledge: 0,
                product_category:[
                    {
                    }
                ],
                receipt: null,
                receipt2: null,
                receipt3: null,
                receipt4: null,
                receipt5: null
            },
            hexTokens: {
                F: {
                    pattern: /[0-9a-fA-F]/,
                    transform: v => v.toLocaleUpperCase()
                },
                G: {
                    pattern: /[0-9a-zA-Z]/,
                    transform: v => v.toLocaleUpperCase()
                }
            },
            formSendedStatus: null,
            formSubmitted:false,
            formSendedErrorCode:null,
            imageApiError:false,
            prodCategories:[
                "Milka kistáblás csokoládé 82-100g",
                "Milka praliné 44-169g, Favourites 159g",
                "Milka mogyorókrém 350g /600g",
                "Milka karácsonyi termék",
                "Milka nagytáblás csokoládé 250-300g",
                "Milka szeletes csokoládé 31-37g",
                "Milka keksz 30-185g"
            ]
        }
    },
    computed:{
        buyType(){
            return this.form.buy_type ?? null
        },
        productPcs(){
            // 1 - 10 pcs generate            
            let pcs =[...Array(25).keys()]
            pcs = pcs.filter(function(a){
                return a%3===0;}
            );

            return pcs.splice(1)          
        },
        purchase_time(){
            //for the API send
            return this.form.date_of_bought + ' ' + this.form.time_of_bought
        },
        offlineShops(){
            let shopAll = this.$store.state.shops.map((x) => x)        
            shopAll.shift()
            return shopAll
        },
        // tooltipForCode(){
        //     let NAVcodeText = ""
        //     let onlineCodeText ="A rendelés számának utolsó 4 karaktere."
        //     return this.form.buy_type == 'shop' ? NAVcodeText ? onlineCodeText
        // }
    },
    watch:{
        buyType(newValue){
            console.log('wath buy type')
            setTimeout(() => {
                // var element = document.querySelector('[data-bs-toggle="tooltip"]')                
                // element.setAttribute('data-bs-original-title', element.title);
                // element.setAttribute('aria-label', element.title);
                
                let element = null

                if (newValue == 'online'){
                    element = document.querySelector('#online_code + [data-bs-toggle="tooltip"]')
                    element.setAttribute('data-bs-original-title', 'A rendelés számának utolsó 4 karaktere.');
                } else {
                    element = document.querySelector('#nav_code + [data-bs-toggle="tooltip"]')
                    element.setAttribute('data-bs-original-title', '5 karakter');
                }
                                
            }, 100);
            
        }
    },
    methods: {
        fillProductCategory(category, event, ind){                        

            if (parseInt(event) === 1){
                this.form.product_category[ind].value = category
            } else {
                this.form.product_category[ind].value = '-'
            }            
        },
        changeFile( form){
            console.log("changeFile", form)
            this.form.receipt = form.receipt
            this.form.receipt2 = form.receipt2
            this.form.receipt3 = form.receipt3
            this.form.receipt4 = form.receipt4
            this.form.receipt5 = form.receipt5
        },
        defaultFormState(){
            this.form = {
                buy_type: 'shop',
                where_bought: '',
                ap_code: '',
                nav_code: '',
                online_code:'',
                date_of_bought: '',
                time_of_bought: '',                
                purchase_time:'',   
                product_count: '',                          
                // acknowledge: 0,
                product_category:[
                    {}
                ],
           }
        },
        goToError(err){            
            let _this = this
            //scroll to the first error
          this.setErrorMsgs(err, 'observerSend').then(()=>{                               
              let firstError = document.querySelector('.send-form .input-error')
              if (firstError){
                  _this.scrollToElement(firstError, 80)       
              }
              
          })                                       
        },
        checkErrors(){
            let _this = this;
            setTimeout( function(){
                let firstError = document.querySelector('.send-form .input-error')
                if (firstError)                    
                    _this.scrollToElement(firstError, 80)
            }, 500)
        },
        goToResultBlock(){
            let _this = this
            
            setTimeout(() => {
                let resultBlock = document.getElementById('formResultBlock')
                //resultBlock.scrollIntoView()
                _this.scrollToElement(resultBlock, 80)
            }, 1000);
        },
        hasProductCategory(){
            return this.form.product_category.length > 0 ? true : false
        },
        getProductCategoryString(){
            //CUSTOM
            let categoryString = ""
            this.form.product_category.forEach( item => {
                categoryString += item.value + "; "
            })

            // console.log("getProductCategoryString", categoryString)

            return categoryString
        },
        getFormContent(){
            let formContent = {}

            if (this.needFileUpload){
                console.log("getFormContent - needfileupload")
                let _this = this
                let formData = new FormData();
                
                formData.append("buy_type", _this.form.buy_type);
                formData.append("where_bought", _this.form.where_bought);

                if (_this.form.buy_type == 'shop'){
                    formData.append("ap_code", 'APA' + _this.form.ap_code);
                    formData.append("online_code", '');
                    formData.append("nav_code", _this.form.nav_code);
                } else {
                    formData.append("online_code", _this.form.online_code);
                    formData.append("ap_code", '');
                    formData.append("nav_code", '');
                }            
                                                    
                if (_this.form.buy_type == 'shop' ){
                    formData.append("purchase_time", _this.purchase_time);
                } else {
                    formData.append("purchase_time", _this.form.date_of_bought);
                }
                            
                // if (_this.needAcknowledge){
                //     formData.append("acknowledge", _this.form.acknowledge);
                // }
                
                formData.append("receipt", _this.form.receipt);
                formData.append("receipt2", _this.form.receipt2);
                formData.append("receipt3", _this.form.receipt3);
                formData.append("receipt4", _this.form.receipt4);
                formData.append("receipt5", _this.form.receipt5);     

                //CUSTOM
                let prodCat = _this.getProductCategoryString()
                formData.append("product_category", prodCat )                           

                formContent = formData
            } else {
                console.log("getFormContent - no file upload")
                //SHOP
                if (this.form.buy_type == 'shop'){                    
                    this.form.ap_code = 'APA' + this.form.ap_code 
                    this.form.purchase_time = this.purchase_time
                    //fot not to sen two code
                    this.form.online_code = ''
                }
                //ONLINE
                if (this.form.buy_type == 'online'){                    
                    this.form.purchase_time = this.form.date_of_bought
                    //fot not to sen two code
                    this.form.ap_code = ''
                    this.form.nav_code = ''
                    this.form.time_of_bought = ''
                }

                var sendData = JSON.parse(JSON.stringify(this.form));

                formContent = sendData
            }

            return formContent
        },
        onSubmit(){
            let _this = this        

            //PRODUCT CATEGORY
            //set errors def. state - CUSTOM BLOCK
            this.productCategoryListErrors = false
             // otherClientValidation contains the checkbox list validation
            if (!this.hasProductCategory()){
                this.productCategoryListErrors = true
            } 

             // FILE UPLOAD - if the form needs file upload
            if (this.needFileUpload){
                this.imageApiError = false

                //kötelező blokkot feltölteni
                if (!_this.form.receipt) {
                    _this.imageError = true;
                return;
                }
            }                                   
           

            if (!_this.formSubmitted) { 
                _this.formSubmitted = true           //don't start two form submit line                    

                _this.post('profile/step1/upload', _this.getFormContent())
                .then((response) => {                   
                    //SUCCESS FORM SENDED
                    if (response.data.status === true) {                        
                        _this.formSendedStatus = 'success' //for success mgs
                        _this.goToResultBlock() // go to the success msg block
                        _this.$emit('compSend')
                        let GTMcategory = 'palyazat_bekuldes'
                        _this.setGtm('sikeres', GTMcategory)
                    } 

                    _this.defaultFormState()
                    _this.formSubmitted = false
                    //this.$nextTick(() => this.$refs.observer.reset());

                }).catch(function (err) {
                    // NOT SUCCESS FORM SENDED
                    console.log('Sikertelen ág')
                    console.log(err);   
                    _this.formSubmitted = false
                    let GTMcategory = null

                    let errorsArray = Object.entries(err.response.data.error)
                    for (const [errorName, errorValue] of errorsArray) {
                        //limitek elérése - napi max, vásárlási nap blokk max, össz feltöltés
                        if (errorName == "maximum_daily_uploads" || errorName == 'maximum_uploads' || errorName == 'purchase_time' && errorValue == 'limit_reached_for_that_day' || errorName == 'nav_code') {
                            _this.formSendedStatus = 'limit_reached'
                            _this.formSendedErrorCode = errorName != 'purchase_time' ? errorName : errorValue
                            _this.goToResultBlock()  
                            //GTM 
                            if ( errorName == 'maximum_daily_uploads'){
                                GTMcategory = 'palyazat_bekuldes_napi_limit'
                            } else if ( errorName == 'maximum_uploads' ){
                                GTMcategory = 'palyazat_full_max_limit'
                            } else if( errorName == 'nav_code'){
                                GTMcategory = 'palyazat_ervenytelen_nav_code'
                            } else  if (errorName == 'purchase_time' && errorValue == 'limit_reached_for_that_day'){
                                GTMcategory = 'palyazat_bekuldes_egy_napi_vasarlas_limit'
                            }                       
                        } else if ( errorName == 'ap_code' && errorValue == 'same_send' || errorName == 'online_code' && errorValue == 'same_send'){
                            _this.formSendedStatus = 'not_success'
                            _this.goToResultBlock()                             
                            GTMcategory = 'palyazat_bekuldes_ismetlodo'
                        } else if (errorName === "receipt" || errorName === "receipt2" || errorName === "receipt3" || errorName === "receipt4" || errorName === "receipt5"){
                              _this.imageApiError = true                      
                        } else {
                             _this.goToError(err)                
                             GTMcategory = 'egyeb_hiba'                                  
                            //this.$nextTick(() => this.$refs.observer.reset());
                        }
                    }                                                                        
                    _this.setGtm('sikertelen', GTMcategory)               

                });
            }
        },
        goBackToForm(){
            this.defaultFormState()
            this.uploadedFiles = []
            this.formSendedErrorCode = null
            this.formSendedStatus = null
            this.$emit('resetCompSend')
        },
         goBackToFormMySendings(){
            this.goBackToForm()
            let _this = this
            setTimeout(() => {                
                let element = document.querySelector('#bekuldeseim')
                if (element){
                    _this.scrollToElement(element, 80)       
                }
            }, 1000);
        },
        setGtm(result, GTMcategory){
             this.GTtrackGA4({
                'event' : 'event',
                'category': GTMcategory,
                'action': 'bekuldes_gomb_kattintas',
                'label': result,                
                'button': 'kodfeltoltes',
                'clicked_text':'Beküldés',
                'success': result
            })
        }       
    }
}
</script>