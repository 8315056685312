<template>
    <div>
        <div class="hero__outer">
            <div class="hero" id="hero">        
                <div class="hero-container">
                    <!-- CONTENT -->
                    <div class="container-xl">
                        <div class="row py-5">
                            <!-- BAGOLY -->
                            <div class="owl__container col-12 col-lg-5 order-lg-0 order-1 d-flex flex-column justify-content-end align-items-center align-items-end position-relative">
                                <img src="imgs/bagoly.png" class="mb-3 position-absolute owl" alt="logo"/> 
                                <img src="imgs/hero-follow.png" class="img-fluid mb-3 position-lg-absolute drink" alt="logo"/> 
                            </div>
                            <!-- Infok -->
                            <div class="col-12 col-lg-7 order-lg-1 order-0">
                                <div class="row">
                                    <div class="col-12 text-md-end">
                                        <img src="imgs/logo.svg" class="img-fluid mb-3" alt="logo"/>  
                                    </div> 
                                    <div class="col-12 text-md-start text-primary">
                                        <div class="hero-top">
                                            <h1 class="text-yellow p-3">
                                                Játssz és nyerj az új guarana no sleep energiaitallal!
                                            </h1>                                                                
                                        </div>
                                    </div>    
                                    <div class="col-12">
                                        <div class="hero__lead text-md-start text-center text-dark mt-3 p-3">
                                            <p>Vásárolj 3 db Guarana No Sleep energiaitalt, töltsd fel a blokkon található AP kódot és nyerj!</p>
                                            <p class="hero__time-text m-0">A promóció időtartama:</p>
                                            <p class="text-black fw-600 hero__date-wrap">2022. november 10 – december 07. </p>
                                            <div class="row hero__winnings">
                                                <div class="col-12 col-md-4 d-flex align-items-start flex-column justify-content-center order-sm-0 order-1">
                                                    <img src="imgs/napi-winners.png" class="mb-md-0 mx-auto bunny" alt="Naponta" width="200"/>
                                                    <div class="d-flex flex-column justify-content-end">
                                                        <p class="hero__winnings__title text-start m-0">NAPI 1X</p>
                                                        <p class="m-0 text-start">Guarana No Sleep <span class="d-none d-md-block"></span>sapka</p>
                                                    </div>
                                                </div>
                                                <div class="col-12 col-md-4 d-flex align-items-start flex-column justify-content-center py-3 py-sm-0 order-0 order-sm-1">
                                                    <img src="imgs/fodij-winners.png" class="img-fluid mb-0 mx-auto" alt="Fődíj" width="200"/>
                                                    <div class="d-flex flex-column justify-content-end">
                                                        <p class="hero__winnings__title text-start m-0">FŐDÍJ</p>
                                                        <p class="m-0 text-start">Egy bagoly állatkerti<span class="d-none d-md-block"></span> örökbefogadása</p>
                                                    </div>
                                                </div>
                                                <div class="col-12 col-md-4 d-flex align-items-start flex-column justify-content-center order-2">
                                                    <img src="imgs/heti-winners.png" class="img-fluid mb-md-0 mx-auto fridge" alt="Heti" width="200"/>
                                                    <div class="d-flex flex-column justify-content-end">
                                                        <p class="hero__winnings__title text-start m-0">HETI 1X</p>
                                                        <p class="m-0 text-start">Guarana No Sleep <span class="d-none d-md-block"></span>energiaitallal<span class="d-none d-md-block"></span> feltöltött mini hűtő</p>
                                                    </div>
                                                </div>                                                        
                                            </div>
                                        </div>   
                                    </div>
                                    <div class="col-12">
                                        <div class="text-lg-start text-center text-dark mt-3">
                                            <button
                                                class="btn btn-primary mb-3 order-md-2 me-md-2 md-btn"
                                                v-scroll-to="{el:!$store.state.user ? '#regisztracio' : '#palyazat',  offset: -80}"
                                                @click="setGtm('Játszom')"
                                            >
                                                Játszom
                                            </button>
                                            <a class="btn btn-third me-0 ms-lg-2 order-md-1 mb-3 md-btn" :href="`/pdf/Jatekszabalyzat.pdf?` + TimeStamp" target="_blank" @click="setGtm('Játékszabályzat')">
                                                Játékszabályzat
                                            </a>   
                                        </div>   
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Ribbon />
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import Ribbon from '@/components/Ribbon.vue'

export default {
    components:{
        Ribbon
    },
    methods: {      
        setGtm(btnName){
            this.GTtrackGA4({
                'event' : 'event',
                'category': 'jatekleiras',
                'action': 'gomb_kattintas',
                'label': btnName,                
                'clicked_text':btnName,
                'action_type': 'gomb_kattintas'
            })
        }
    }
}
</script>