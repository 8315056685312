<template>
    <div id="nyertesek" class="winners pb-0">
        <div class="container-xl">
            <h2 class="font-header text-black text-uppercase text-center mb-5 mt-0">
                Nyertesek
            </h2>
            
            <div class="row pb-5">                
                <div class="col-12 col-xl-8 offset-xl-2 mb-0 mb-md-5 text-center text-black mb-5">
                    <p class="m-0 winners__text">
                        Kedves Játékosunk!
                        <br><br/>A napi nyeremények nyertesei az adott napot követő első munkanapon (pénteki/szombati/vasárnapi nyeremények esetében a következő hétfőn), míg a heti nyeremények nyertesei csütörtökönként <b>(2022. november 17., november 24., december 01., december 08.)</b> kerülnek kiértesítésre a Lebonyolító által küldött e-mail üzenetben.
                    </p>
                </div>
                <div class="col-12 col-xl-8 offset-xl-2 mb-0 mb-md-5 text-center text-dark mb-5">
                    <div class="winners__event p-4">
                        <h4 class="text-yellow text-uppercase">Sorsolás</h4>
                        <p class="winners__text text-white">A fődíj sorsolására <span class="fw-800">2022. december 12-én 14:00 órakor</span> kerül sor. A sorsolás helyszíne: A4C Marketing Kft. székhelye (1092 Budapest, Bakáts tér 2., bejárat a Bakáts utca 5-7. felől)</p>
                    </div> 
                </div> 
            </div>
            
            <!-- NYertesek -->
            <div class="row">
                <!-- DROPDOWN BTN -->
                <div class="col-12">
                    <div class="d-md-flex justify-content-around align-items-top">
                        <div 
                            v-for="win in wins"
                            :key="'winners' + win.id"
                            class="winners-collapse d-flex flex-column justify-content-center align-items-start mb-5 mb-xl-0"
                            data-bs-toggle="collapse" 
                            :data-bs-target="'#winner-' + win.id" 
                            aria-expanded="false" 
                            aria-controls="collapseExample"
                        >
                            <div class="win text-center">
                                <img :src="`imgs/${win.img}`"  :class="win.id == 3 ? 'bunny':'' || win.id == 2 ? 'fridge':''" class="img-fluid win--main" :alt="win.name" :style="win.styleCss" />
                                <div class="d-flex justify-content-between align-items-center mb-2 mx-2">
                                    <div>
                                        <div class="text-dark display-6 font-secondary pt-2 winners__title text-uppercase text-start fw-600" v-html="win.line1"></div>
                                        <div class="text-dark pt-2 text-start fw-400" v-html="win.line2"></div>
                                    </div>
                                    <div class="winners-collapse-button">
                                        <span></span>                              
                                    </div>
                                </div>
                                <div class="winners__border py-3 mx-2">
                                    <p class="text-black m-0 text-start">{{win.text}}</p>
                                </div>
                            </div>                           
                            
                            <!-- DROPDOWN LIST -->
                            <div 
                                class="collapse w-100 mx-2" :class="loginShow == 0 ? 'show':''" 
                                :id="'winner-' + win.id"                       
                            >
                                <div  v-if="win.winners.length>0 && loginShow" class="winners-collapse-content me-3">
                                    <h4 class="text-uppercase text-black pt-2">Nyertesek</h4>
                                    <p 
                                        v-for="(winner, idx) in win.winners"
                                        :key="'name' + idx"
                                        class="mb-1 text-left text-black"
                                    >
                                        {{winner.name}} - {{winner.winning_time}}
                                    </p>
                                </div>
                                <div v-else class="winners-collapse-content me-3">
                                    <h4 class="text-uppercase text-black pt-2">Nyertesek</h4>
                                    <p class="text-dark font-secondary" v-if="loginShow != 0">Hamarosan..</p>
                                    <p v-else class="text-dark font-secondary">A kisorsolt nyertesek részére a nyeremények átadása megtörtént!</p>
                                </div>
                            </div>                    
                        </div>
                    </div>
                </div>
            </div> 

            <div class="row">
                <div class="col-12 my-4 my-md-5">
                    <div class="d-flex flex-column">
                        <p class="text-center font-secondary my-5 py-md-5">A nyeremények képe csak illusztráció!</p>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>


<script>
export default {
    data () {
        return {
            winnersAll:[],
            wins: [        
                {
                    id: 3,
                    name: 'napi',
                    type:'napi',
                    line1: 'NAPI 1x',
                    line2: 'Guarana No Sleep sapka',                    
                    winners: [],
                    img:'napi-winners.png',
                    text:'A sapka kötött anyagú, méretét tekintve one size, a Guarana No Sleep logó pedig hímzéssel kerül rá.'                  
                },      
                {
                    id: 1,
                    name: 'fődíj',
                    type:'fodij',
                    line1: 'FŐDÍJ',
                    line2: 'Egy bagoly állatkerti<br/>örökbefogadása',                    
                    winners: [],
                    img:"fodij-winners.png",
                    text: 'A Nyíregyházi Állatparkban egy UHU baglyot fogadhatsz örökbe, az örökbefogadó neve kikerül a faj kifutójára is, valamint 4 db tiszteletjegyet is kapsz, így családoddal el tudsz látogatni az állatkertbe. A bagoly örökbefogadásával az állatpark 350.000 forint támogatásban részesül. '                
                },                                
                {
                    id: 2,
                    name: 'heti',
                    type:'heti',
                    line1: 'HETI 1x',
                    line2: 'Guarana No Sleep energiaitallal<br>feltöltött mini hütő',                    
                    winners: [],
                    img:'heti-winners.png',
                    text:'A mini hűtő térfogata 40 liter, méretei: 43 x 50 x 47 cm, melyet 24 darab Guarana No Sleep Classic és 24 db Guarana No Sleep Cactus energiaitallal töltünk fel neked!'                  
                }  
            ]
        }
    },
    methods: {
        // imgUrl(nr){
        //     return '/imgs/win-' + nr + '.png'
        // },
        selectWinners(){
            let _this = this
            this.wins.forEach( item => {                
                let winTypeWinners = _this.winnersAll.filter( element => element.type == item.type )
                // console.log('winTypeWinners',winTypeWinners)
                if (winTypeWinners)
                    item.winners = winTypeWinners
            })
        }
    },
    created(){
        this.get('info/winners',{})
        .then((resp) => {
          this.winnersAll = resp.data.winners
          this.selectWinners()
        }).catch((err) => {
            console.log(err)
        })
    }
    
}
</script>